/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-gray-100;
  }
}

body {
  color: #33475b;
}

h2 {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
}

h3 {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  background: #e1e1e1;
  height: 100%;
}

h2,
h3,
p,
label,
input {
  color: #33475b;
}

.sf__cata__wrapper {
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
}

.sf__cata__container {
  border: 1px solid #e1e1e1;
  margin-top: 30px;
  flex: 1 0 230px;
}

.sf__cata__header {
  height: 72px;
}

.sf__cata__header h3,
.sf__best__player__container h3 {
  display: flex;
  align-items: center;
  text-align: left;
}

.sf__best__player__container h3 {
  justify-content: center;
}

.sf__cata__header img,
.sf__best__player__container img{
  width: 75px;
}

.sf__cata__container p {
  padding: 15px;
}

.sf__teamplayer__table {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.sf__table__content__container {
  flex: 1 0 203px;
  border: 1px solid #e1e1e1;
}

.sf__table__content__container__header {
  color: #33475b;
  text-align: center;
  font-weight: bold;
  height: 50px;
  background: #e1e1e1;
}

.sf__table__content__container__content {
  padding: 15px;
  color: #33475b;
  font-size: 0.85rem;
}

.sf__best__player__container {
  margin-bottom: 30px;
}

.sf__nomination__heading {
  margin-bottom: 30px;
}

.sf__nomination__heading p {
  font-style: italic;
  text-align: center;
  font-size: 0.75rem;
}

.card {
  position: relative;
  padding: 25px;
  // height: fit-content;
  flex: 0 0 50%;
}

.card-content-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
  pointer-events: none;
}

.card-content-container.open {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 1;
  overflow: hidden;
  padding: 40px 0;
}

.card-content {
  pointer-events: auto;
  position: relative;
  // border-radius: 20px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.open .card-content {
  height: auto;
  max-width: 700px;
  overflow: hidden;
  pointer-events: none;
}

.card-open-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.card-image-container {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 420px;
  width: 100vw;
}

.open .card-image-container,
.open .title-container {
  z-index: 1;
}

.title-container {
  // position: absolute;
  top: 15px;
  left: 15px;
}

.open .title-container {
  top: 30px;
  left: 30px;
}

.category {
  font-size: 14px;
  text-transform: uppercase;
}

.overlay {
  z-index: 1;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  will-change: opacity;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.overlay a {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw;
  left: 50%;

  transform: translateX(-50%);
}

.content-container {
  padding: 460px 35px 35px 35px;
  max-width: 700px;
  width: 90vw;
}

.motivation-modal h4 {
  font-size: 1em;
  font-weight: 600;
  margin: 10px 0 5px 0;
  color: #565656;
}

.motivation-modal p {
  color: #888888;
}

@media only screen and (max-width: 800px) {
  .card {
    flex: 0 0 50%;
    max-width: 50%;
    height: auto;
  }

  .card:nth-child(4n + 1),
  .card:nth-child(4n + 4) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .motivation-modal {
    max-height: 75vh;
    overflow: scroll;
  }
}

@media only screen and (max-width: 600px) {
  .card {
    flex: 1 0 100%;
    max-width: 100%;
    padding: 10px;
    padding-left: 0;
    padding-right: 0;
  }

  .card:nth-child(4n + 1),
  .card:nth-child(4n + 4) {
    flex: 1 0 100%;
    max-width: 100%;
  }

  .card-content-container.open {
    padding: 0;
  }
}
